import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import {
  ChildNodeProps,
  CustomNodeProps,
  IconMapping,
  NODE_IMAGE_CONSTANT,
} from "../../../utilities/flowBuilder.utility";
import { AnalyticsCardRenderer, ChildNodes } from "../customNode";
import {
  ChildNodeContainer,
  ChildNodeText,
  DripNodeContainer,
  NodeStyle,
} from "../style";
import CarousalNode from "./carousalNode";
import ProductAndCatalogNode from "./productAndCatalogNode";
import { Gap, MessageNodeStyle } from "./style";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../state/store";
import { downloadNodeLevelAnalytics } from "../../../services/helpers/AnalyticsHelper";
import { useDetectAnalytics } from "../useDetectAnalytics";
import {
  COLORS,
  IconButton,
  ResizableImage,
  SmallRegular,
  Spinner,
  TitleSmall,
  Tooltip,
} from "@bikdotai/bik-component-library";
import DownloadIcon from "../../../icons/DownloadIcon";
import ErrorReportIcon from "../../../icons/errorReport";

import { generateCSV } from "../../../utilities/fileDownload";
import useRetryHook from "../retryHook";
import { useDispatch } from "react-redux";
import { notificationDataStateActions } from "../../../state/notificationDataState";
import RefreshIcon from "../../../icons/refreshIcon";
import { homeDataAction } from "../../../state/homeState";
import { captureErrorToSentry } from "../../../utilities/sentryHelper";
import { calculatePercentage, getMessageAnalytics } from "./analyticsUtil";
import { CURRENCY_SYMBOLS } from "../../../app/messageBlock/Constants";
import { backgroundBasedOnSubType } from "../util";
import { SummaryAnalyticsViewTabName } from "../../../components/header/SummaryAnalytics";
import { RecordEventHelper } from "../../../utilities/RecordEventHelpers";
import {
  AmplitudeEventStep,
  AmplitudeKeys,
  TargetPlatform,
} from "../../../utilities/AnalyticsTypes";
import { ChildNodeWithoutButton } from "./ChildNodeWithoutButton";
import { NodeSubType } from "../../../models/nodeSubType";
import { ORDER_DETAIL_STATUSES } from "../../../components/order-blocks/constants";

const DONT_SHOW_NEXT_STEP = ["browse_collection", NodeSubType.WHATSAPP_PAYMENT];

const MessageBlockNode = (props: CustomNodeProps) => {
  const {
    nodeData,
    leftHandlerStyle,
    rightHandlerStyle,
    isConnectable,
    editNode,
    onHover,
  } = props;
  const [thumbnail, setThumbnail] = useState<any>();
  const [messageAnalytics, setMessageAnalytics] = useState<{
    [key: string]: string;
  }>({});
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isErrorDownloading, setIsErrorDownloading] = useState<boolean>(false);
  const [isRetrying, setIsRetrying] = useState<boolean>(false);
  const location = useLocation();
  const isAnalytics = useDetectAnalytics();
  const homeState = useAppSelector((state) => state.homeState);

  const storeState = useAppSelector((state: any) => state.storeState);
  const storeInfoState = useAppSelector((state) => state.storeInfoState);

  const [isNodePresent, setIsNodePresent] = useState<boolean>(
    storeState?.flow?.[props?.id]
  );
  const [isFallback, setIsFallback] = useState<boolean>(false);
  const [showNextStep, setShowNextStep] = useState<boolean>(
    !DONT_SHOW_NEXT_STEP.includes(nodeData.subType)
  );
  const isSSO = homeState.userData?.loginType === "sso";
  const { retry } = useRetryHook();
  const dispatcher = useDispatch();

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    updateNodeInternals(props?.nodeData.nodeId);
  }, [props?.nodeData.childNodes]);

  const currency = CURRENCY_SYMBOLS[storeInfoState.currency] ?? "";

  let headers: string[] = [];

  useEffect(() => {
    if (homeState?.analyticsData?.nodeAnalytics) {
      PopulateAnalytics();
    }
    if (nodeData && Object.keys(nodeData)?.length) {
      getThumbnail(nodeData?.media, nodeData?.mediaUrl);
    }
    const storeStateForNode = storeState?.flow?.[nodeData?.nodeId];
    if (nodeData.subType === "order_list" && storeStateForNode?.v2) {
      setShowNextStep(false);
    }
    if (storeStateForNode) {
      setIsNodePresent(true);
      let isFallback = false;
      switch (nodeData.subType) {
        case "catalog_list":
        case "order_list":
          isFallback = storeStateForNode?.v2
            ? !!storeStateForNode?.template?.M1?.fallback?.fallbackDuration &&
              storeStateForNode?.template?.M1?.fallback?.fallbackV2
            : !!storeStateForNode?.template?.M2?.fallback?.fallbackDuration &&
              storeStateForNode?.template?.M2?.fallback?.fallbackV2;
          break;
        case "ask_phone":
        case "ask_email":
        case "ask_name":
          isFallback =
            !!storeStateForNode?.template?.M1?.fallback?.fallbackDuration &&
            storeStateForNode?.template?.M1?.fallback?.fallbackV2;
          break;
        default:
          isFallback =
            !!storeStateForNode?.fallback?.fallbackDuration &&
            storeStateForNode?.fallback?.fallbackV2;
          break;
      }
      setIsFallback(isFallback ?? false);
    }
  }, [nodeData, homeState, location]);

  const addEllipsis = (text: string) => {
    if (text && text.length > 100) return text.slice(0, 101) + "...";
    return text;
  };

  nodeData.carousals &&
    nodeData.carousals.map((carousal: any) => {
      headers.push(carousal.title);
    });

  const getThumbnail = (mediaType: string, url: string) => {
    const isValidURL = ["https", "http", "www", "cdn"].filter((el) =>
      url?.startsWith(el)
    )?.length;
    if (!mediaType || !mediaType.trim() || mediaType.toLowerCase() === "text")
      setThumbnail("");
    else if (isValidURL && mediaType.toLowerCase() === "image")
      setThumbnail(
        <ResizableImage
          imageUrl={url}
          targetSize={256}
          style={{ objectFit: "cover" }}
        />
      );
    else if (isValidURL && mediaType.toLowerCase() === "video")
      setThumbnail(
        <video controls preload="none">
          <source src={url} type="video/mp4" />
          <p>Your browser does not support the video tag.</p>
        </video>
      );
    else setThumbnail(NODE_IMAGE_CONSTANT[mediaType]);
  };

  const retryNLA = async () => {
    setIsRetrying(true);
    const response = await retry(nodeData);
    if (!response) {
      dispatcher(
        notificationDataStateActions.setNotifications({
          successMessage: "",
          errorMessage: "Something went wrong",
        })
      );
    } else {
      dispatcher(
        notificationDataStateActions.setNotifications({
          successMessage: "Retry Success",
          errorMessage: "",
        })
      );
    }
    setIsRetrying(false);
  };

  const approve = () => {
    if (homeState.retryAccess) {
      retryNLA()
        .then()
        .catch((e) => {
          captureErrorToSentry(
            e,
            `Something went wrong in messageNode retryNLA`
          );
        });
    } else {
      dispatcher(
        homeDataAction.addState({
          ...homeState,
          showPwModal: true,
        })
      );
    }
  };

  const PopulateAnalytics = () => {
    if (nodeData && Object.keys(nodeData)?.length) {
      const nodeAnalytics = homeState?.analyticsData?.nodeAnalytics;

      const triggered = nodeAnalytics?.[nodeData.nodeId]?.triggered_count;
      const read = nodeAnalytics?.[nodeData.nodeId]?.read_count;
      const answered = nodeAnalytics?.[nodeData.nodeId]?.answered_count;
      const sent = nodeAnalytics?.[nodeData.nodeId]?.sent_count;
      const delivered = nodeAnalytics?.[nodeData.nodeId]?.delivered_count;
      const revenue = nodeAnalytics?.[nodeData.nodeId]?.revenue;
      const clicked = nodeAnalytics?.[nodeData.nodeId]?.clicked_count;
      const executed = nodeAnalytics?.[nodeData.nodeId]?.executed_count;

      const del = calculatePercentage(delivered, sent);
      const readPercentage = calculatePercentage(read, sent);

      if (
        nodeAnalytics &&
        (homeState?.channel === "whatsapp" || homeState?.channel === "email")
      ) {
        if (nodeData?.subType === "send_sms") {
          setMessageAnalytics({
            Sent: sent,
            Delivered: calculatePercentage(delivered, triggered),
            Clicked: clicked ? calculatePercentage(clicked, sent) : "--",
            Revenue: revenue && revenue > 0 ? `${currency} ${revenue}` : "--",
          });
        } else {
          setMessageAnalytics(
            getMessageAnalytics(
              nodeData?.subType,
              triggered,
              executed,
              sent ?? "--",
              del,
              readPercentage,
              answered,
              revenue,
              clicked,
              currency,
              storeInfoState.currency
            )
          );
        }
      } else if (homeState?.channel === "instagram") {
        if (
          nodeData?.subType === "ask_name" ||
          nodeData?.subType === "ask_email" ||
          nodeData?.subType === "ask_phone" ||
          nodeData?.subType === "question" ||
          nodeData?.subType === "CSAT/NPS" ||
          answered
        ) {
          setMessageAnalytics({
            Triggered: triggered ?? "--",
            Answered: calculatePercentage(answered, triggered),
          });
        } else {
          setMessageAnalytics({
            Triggered: triggered > 0 ? triggered : "--",
            Sent: calculatePercentage(sent, triggered),
            Opened: read ? read : "--",
            Revenue: revenue && revenue > 0 ? `${currency} ${revenue}` : "--",
          });
        }
      }
    }
  };

  const handleNLADownload = async (e: any, isError = false) => {
    e.stopPropagation();
    if (isError) {
      setIsErrorDownloading(true);
    } else {
      setIsDownloading(true);
    }
    const buttons: { [key: string]: string } = {};
    const carouselCardMapping: { [key: string]: string[] } = {};

    nodeData?.childNodes?.forEach((item: any) => {
      buttons[item?.buttonId] = item?.name;
    });

    const payload: any = {
      storeId: homeState.storeId,
      flowId: homeState.flowId,
      startDate: homeState.analyticsData?.startDate,
      endDate: homeState.analyticsData?.endDate,
      channel: homeState.channel,
      nodeId: nodeData.nodeId,
      nodeSubType: nodeData.subType,
      nodeType: nodeData.nodeType ?? nodeData.type,
      basedOnTriggeredDate:
        homeState?.analyticsData?.selectedView ===
        SummaryAnalyticsViewTabName.FUNNEL_VIEW,
    };
    if (nodeData.subType === NodeSubType.WHATSAPP_PAYMENT) {
      payload.conditions = buttons;
    } else {
      payload.buttons = buttons;
    }

    if (isError) {
      payload.onlyErrorReport = true;
    }
    if (nodeData.subType === "carousel" || nodeData.subType === "wa_carousel") {
      nodeData?.carousel.forEach((card: any) => {
        carouselCardMapping[card?.id] = [];
        card?.buttonsList?.forEach((button: any) => {
          buttons[button?.buttonKey] = button?.buttonText;
          carouselCardMapping[card?.id] = [
            ...carouselCardMapping[card?.id],
            button?.buttonKey,
          ];
        });
      });
      payload.carouselCardMapping = carouselCardMapping;
    }
    const response = await downloadNodeLevelAnalytics(payload);
    if (response) {
      generateCSV(
        response,
        `${isError ? "error_" : ""}${homeState.flowId}_${nodeData.nodeId}_${nodeData.subType}`
      );
      const eventHelper = RecordEventHelper.getInstance();
      eventHelper.trackEvent(
        TargetPlatform.Amplitude,
        AmplitudeKeys.service_used,
        {
          step: isError
            ? AmplitudeEventStep.error_report_downloaded
            : AmplitudeEventStep.node_analytics_downloaded,
          screen: "journey_builder_analytics",
        }
      );
    }
    if (isError) {
      setIsErrorDownloading(false);
    } else {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <MessageNodeStyle subType={nodeData?.subType} channel={homeState.channel}>
        {(nodeData?.description ||
          (nodeData?.subType === "carousel" &&
            !!nodeData?.childNodes?.length) ||
          (nodeData?.subType === "product_carousel" &&
            !!nodeData?.childNodes?.length) ||
          (nodeData?.subType === "wa_carousel" &&
            !!nodeData?.childNodes?.length)) && (
          <Handle
            id="target"
            type="target"
            position={Position.Left}
            style={leftHandlerStyle}
            isConnectable={isConnectable && isAnalytics}
          />
        )}
        <NodeStyle
          subType={nodeData?.subtype}
          mediaUrl={nodeData?.mediaUrl}
          className="customNode flex--column"
          onMouseEnter={() => onHover(props?.id, nodeData?.subType)}
          onMouseLeave={() => onHover(null, undefined)}
          nodeColor={backgroundBasedOnSubType(
            nodeData.subType,
            homeState.channel
          )}
          onClick={
            !isAnalytics
              ? () => {
                  editNode(
                    nodeData?.type,
                    props?.id,
                    nodeData?.subType,
                    nodeData?.nodeIndex,
                    nodeData?.automationChannel
                  );
                }
              : () => {}
          }
        >
          <div className="customNode__header flex justify-content-between align-items-center">
            <div className="flex">
              <span className="customNode__header__icon icon-wrapper">
                {IconMapping(nodeData?.subType, homeState.channel!)}
              </span>
              <div style={{ width: 8 }}></div>
              <span className="customNode__header__title">
                {nodeData?.title || nodeData?.subType}
              </span>
            </div>
            {isAnalytics && (
              <div className={"ml-auto d-flex"}>
                {isSSO && (
                  <div>
                    {isRetrying ? (
                      <div style={{ width: 32 }}>
                        <Spinner size="small" color={COLORS.content.primary} />
                      </div>
                    ) : (
                      <IconButton
                        Icon={RefreshIcon}
                        style={{ color: "#919191", display: "flex" }}
                        onClick={approve}
                      />
                    )}
                  </div>
                )}
                <div>
                  {isDownloading ? (
                    <div style={{ width: 32 }}>
                      <Spinner size="small" color={COLORS.content.primary} />
                    </div>
                  ) : (
                    /* @ts-ignore */
                    <Tooltip
                      delay={[200, 0]}
                      body="Download node analytics"
                      placement="right"
                    >
                      <IconButton
                        Icon={DownloadIcon}
                        style={{ color: "#919191", display: "flex" }}
                        onClick={handleNLADownload}
                      />
                    </Tooltip>
                  )}
                </div>
                <div>
                  {isErrorDownloading ? (
                    <div style={{ width: 32 }}>
                      <Spinner size="small" color={COLORS.content.primary} />
                    </div>
                  ) : (
                    /* @ts-ignore */
                    <Tooltip
                      delay={[200, 0]}
                      body="Download error report"
                      placement="right"
                    >
                      <IconButton
                        Icon={ErrorReportIcon}
                        style={{ color: "#919191", display: "flex" }}
                        onClick={(e) => handleNLADownload(e, true)}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="customNode__body flex--column">
            {!nodeData?.description &&
              nodeData.subType !== "carousel" &&
              !nodeData.childNodes?.length && (
                <div className="customNode__body__no-content flex">
                  {`Click to add ${
                    ["email_temp_new", "email_csat"].includes(
                      nodeData?.subType ?? ""
                    )
                      ? "Email"
                      : nodeData?.subType?.split("_")?.at(-1)
                  }`}
                </div>
              )}
            {!nodeData?.description &&
              nodeData.subType === "carousel" &&
              !nodeData.childNodes?.length && (
                <div className="customNode__body__no-content flex">
                  {`Click to add ${
                    nodeData?.subType === "email_temp_new"
                      ? "email"
                      : nodeData?.subType?.split("_")?.at(-1)
                  }`}
                </div>
              )}
            {!!nodeData?.media && (
              <div className="customNode__body__image flex">{thumbnail}</div>
            )}
            {!!(!nodeData?.media && nodeData?.header) && (
              <TitleSmall numberOfLines={1}>{nodeData?.header}</TitleSmall>
            )}
            {!!nodeData?.description && (
              <div className="customNode__body__description-message">
                <SmallRegular numberOfLines={2}>
                  {nodeData?.description}
                </SmallRegular>
              </div>
            )}
            {!!nodeData?.slang && (
              <div className="customNode__body__slang">{nodeData?.slang}</div>
            )}
          </div>

          {nodeData?.subType !== "carousel" &&
            nodeData?.subType !== "product_carousel" &&
            nodeData?.subType !== "wa_carousel" &&
            !!nodeData?.childNodes?.filter(
              (i: ChildNodeProps) => !i.buttonId?.startsWith("drip-")
            )?.length && (
              <>
                <div className="customNode__child-nodes flex--column">
                  {nodeData.childNodes.map(
                    (child: ChildNodeProps, index: number) => {
                      if (child.buttonId.startsWith("drip-")) {
                        return <></>;
                      }
                      if (ORDER_DETAIL_STATUSES.includes(child.buttonId)) {
                        return <></>;
                      }
                      return (
                        <div className="customNode__childNodes">
                          <ChildNodes
                            key={child.name}
                            name={child.name}
                            buttonId={child.buttonId}
                            index={index}
                            nodeId={nodeData.nodeId}
                            subType={nodeData.subType}
                            connectorColor={nodeData?.connectorColor}
                            nodeType={"Message"}
                            count={nodeData.childNodes.length}
                            isConnectable={child.isConnectable}
                            description={child.description}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              </>
            )}

          {(nodeData?.subType === "carousel" ||
            nodeData?.subType === "wa_carousel") &&
            !!nodeData?.childNodes?.length && (
              <>
                {nodeData.childNodes.map((nodesArray: any, index: number) => {
                  if (nodesArray?.buttonId?.startsWith("drip-")) {
                    return <></>;
                  }
                  return (
                    <>
                      <CarousalNode
                        nodesArray={nodesArray}
                        nodeData={nodeData}
                        nodesArrayCount={nodeData.childNodes.length}
                        parentIndex={index}
                      />
                      <Gap />
                    </>
                  );
                })}
              </>
            )}

          {nodeData?.subType === "product_carousel" &&
            !!nodeData?.childNodes?.length && (
              <>
                <>
                  <ProductAndCatalogNode
                    child={nodeData.childNodes}
                    index={0}
                    subType={nodeData.subType}
                    nodeId={nodeData.nodeId}
                  />
                  <Gap />
                </>
              </>
            )}

          {nodeData?.childNodes
            ?.filter((child: ChildNodeProps) =>
              ORDER_DETAIL_STATUSES.includes(child.buttonId)
            )
            ?.map((child: ChildNodeProps) => {
              return (
                <ChildNodeWithoutButton
                  childNode={child}
                  isAnalytics={isAnalytics}
                  nodeId={nodeData.nodeId}
                />
              );
            })}

          {isNodePresent && showNextStep && (
            <ChildNodeContainer>
              <ChildNodeText>{"Next Step"}</ChildNodeText>
              <div>
                <Handle
                  type="source"
                  position={Position.Right}
                  id="source"
                  style={{
                    background: "#616161",
                    width: "12px",
                    height: "12px",
                    right: "-6px",
                    zIndex: 100,
                    boxShadow: "0px 0px 0px 5px #E0E0E0",
                    border: "none",
                  }}
                  isConnectable={isConnectable && !isAnalytics}
                />
              </div>
            </ChildNodeContainer>
          )}
          {isFallback && (
            <ChildNodeContainer>
              <ChildNodeText>{"Fallback"}</ChildNodeText>
              <div>
                <Handle
                  type="source"
                  position={Position.Right}
                  id="fallback"
                  style={{
                    background: "#616161",
                    width: "12px",
                    height: "12px",
                    right: "-7px",
                    zIndex: 100,
                    boxShadow: "0px 0px 0px 5px #E0E0E0",
                    border: "none",
                  }}
                  isConnectable={isConnectable && !isAnalytics}
                />
              </div>
            </ChildNodeContainer>
          )}

          {nodeData?.childNodes?.filter((i: ChildNodeProps) =>
            i.buttonId?.startsWith("drip-")
          ).length > 0 && (
            <div
              style={{
                border: "0.5px solid #E0E0E0",
                margin: 12,
              }}
            ></div>
          )}
          <div
            style={{
              marginLeft: "auto",
            }}
          >
            {nodeData?.childNodes?.map((child: ChildNodeProps) => {
              if (child.buttonId?.startsWith("drip-")) {
                return (
                  <DripNodeContainer>
                    <ChildNodeText>
                      <div>{child.name}</div>
                    </ChildNodeText>
                    <div>
                      <Handle
                        type="source"
                        position={Position.Right}
                        id={child.buttonId}
                        isConnectable={isConnectable && !isAnalytics}
                        style={{
                          background: "#616161",
                          width: "12px",
                          height: "12px",
                          right: "-7px",
                          marginLeft: "40px",
                          boxShadow: "0px 0px 0px 5px #E0E0E0",
                          border: "none",
                        }}
                      />
                    </div>
                  </DripNodeContainer>
                );
              } else {
                return <></>;
              }
            })}
          </div>
        </NodeStyle>
      </MessageNodeStyle>
      {isAnalytics &&
        AnalyticsCardRenderer(
          messageAnalytics,
          "100%",
          homeState?.analyticsData?.shimmer
        )}
    </>
  );
};

export default MessageBlockNode;
