import {
  HTTPMethods,
  RequestExecutor,
} from "../../api-helpers/RequestExecutor";
import { getCRMEndpoints } from "../../config";

export class CrmService {
  async getCrmContext(customerId: string, storeId: string) {
    const path = `${getCRMEndpoints()}/chatSessionFunctions-getLatestChatSession`;
    const res = (await RequestExecutor.fetch({
      apiPath: path,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        customerId: customerId,
        storeId: storeId,
      },
    })) as any;
    return res?.data?.chatSession?.chatSessionId;
  }

  async getChatStages(storeId: string) {
    try {
      return await RequestExecutor.fetch({
        apiPath: `${getCRMEndpoints()}/chatStageApiFunctions-getChatStages`,
        apiMethod: HTTPMethods.POST,
        requestBody: { storeId: storeId },
      });
    } catch (error: any) {
      return;
    }
  }

  async getChatLabels(storeId: string) {
    try {
      return await RequestExecutor.fetch({
        apiPath: `${getCRMEndpoints()}/labelsApiFunctions-getAllLabels`,
        apiMethod: HTTPMethods.POST,
        requestBody: { storeId: storeId },
      });
    } catch (error: any) {
      return;
    }
  }

  async getGroupedLabels(
    storeId: string | null = null,
    fetchArchived: boolean
  ) {
    try {
      return await RequestExecutor.fetch({
        apiPath: `${getCRMEndpoints()}/labelsApiFunctions-getAllLabelsGrouped`,
        apiMethod: HTTPMethods.POST,
        requestBody: { storeId },
      });
    } catch (error: any) {
      return;
    }
  }

  async getTeams(storeId: string) {
    try {
      return await RequestExecutor.fetch({
        apiPath: `${getCRMEndpoints()}/teamApiFunctions-getTeams`,
        apiMethod: HTTPMethods.POST,
        requestBody: { storeId: storeId },
      });
    } catch (error: any) {
      return;
    }
  }

  async getUsers(
    storeId: string,
    fetchAssignmentEligibleUsers = true,
    email?: string
  ) {
    try {
      return await RequestExecutor.fetch({
        apiPath: `${getCRMEndpoints()}/userApiFunctions-getUsers`,
        apiMethod: HTTPMethods.POST,
        requestBody: {
          storeId: storeId,
          email: email,
          fetchAssignmentEligibleUsers: fetchAssignmentEligibleUsers,
        },
      });
    } catch (error: any) {
      return;
    }
  }
}
