import { NodeSubType } from "../../../models/nodeSubType";
import { AnalyticsToPercentageConverter } from "../../../services/helpers/AnalyticsHelper";
import {
  truncateValueForIndianStore,
  truncateValueForInternationalStore,
} from "@bikdotai/bik-component-library";

export const calculatePercentage = (numerator: number, denominator: number) => {
  if (denominator === 0) {
    return "--";
  }
  const percentage = (numerator / denominator) * 100;
  if (percentage > 100) {
    return "100%";
  } else if (percentage > 0) {
    return `${AnalyticsToPercentageConverter(numerator, denominator)}%`;
  } else {
    return "--";
  }
};

export const getTruncatedRevenue = (
  revenue: number,
  currency: string,
  currencyThreeLetterCode: string
) => {
  const truncatedRevenue =
    currencyThreeLetterCode === "INR"
      ? truncateValueForIndianStore(revenue)
      : truncateValueForInternationalStore(revenue);
  return revenue > 0 ? `${currency} ${truncatedRevenue}` : "--";
};

export const getMessageAnalytics = (
  subType: string,
  triggered: number,
  executed: number,
  sent: number,
  del: string,
  readPercentage: string,
  answered: number,
  revenue: number,
  clicked: number,
  currency: string,
  currencyThreeLetterCode: string
) => {
  const analytics: { [key: string]: any } = {};

  switch (subType) {
    case "email_temp_new":
      analytics.Sent = sent;
      analytics.Opened = readPercentage;
      analytics.Clicked = clicked ? calculatePercentage(clicked, sent) : "--";
      analytics.Revenue = getTruncatedRevenue(
        revenue,
        currency,
        currencyThreeLetterCode
      );
      break;
    case "catalog_list":
    case "order_list":
      analytics.Triggered = triggered > 0 ? triggered.toString() : "--";
      break;
    case "message":
    case "template":
    case "list":
    case "catalog":
    case "single_product":
    case "multi_product":
    case "cart_info":
    case "carousel":
    case "browse_collection":
    case "wa_carousel":
    case "product_card":
    case NodeSubType.WHATSAPP_PAYMENT:
    case NodeSubType.ORDER_CONFIRMATION:
      analytics.Sent = sent > 0 ? sent.toString() : "--";
      analytics.Delivered = del;
      analytics.Opened = readPercentage;
      analytics.Revenue = getTruncatedRevenue(
        revenue,
        currency,
        currencyThreeLetterCode
      );
      break;
    case "ask_name":
    case "ask_email":
    case "ask_phone":
    case "question":
    case "CSAT/NPS":
    case NodeSubType.WHATSAPP_ADDRESS:
      analytics.Sent = sent > 0 ? sent.toString() : "--";
      analytics.Delivered = del;
      analytics.Opened = readPercentage;
      analytics.Answered = calculatePercentage(answered, sent);
      break;
    case "gmail_reply":
      analytics.Triggered = triggered > 0 ? triggered.toString() : "--";
      analytics.Success =
        (executed / triggered) * 100 > 100
          ? "100%"
          : (executed / triggered) * 100 > 0
            ? `${AnalyticsToPercentageConverter(executed, triggered)}%`
            : "--";
      break;
  }

  return analytics;
};
